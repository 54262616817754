<template>
    <v-row justify="center">

        <!-- View Uncovered Transactions-->
        <v-dialog v-model="dialog"
                  fullscreen
                  persistent
                  hide-overlay
                  transition="dialog-bottom-transition">
            <v-card color="#303030" :style="secondaryBackground">
                <div class="pt-4 pb-1" :style="secondaryBackground" style="background-color:black; height:70px; text-align:center;">
                    <img src="../assets/CryptoTaxSpace_Logo.png" width="120" alt="logo" />
                </div>

                <v-container>

                    <h5 style="color:var(--var-theme-color);">
                        Uncovered Transactions
                    </h5>
                    <br />
                    <div class="elevation-5 mb-5 pl-2 pr-2 pt-2 pb-2" style="background-color:#566573; border-radius:6px;">
                        <span class="text-white">
                            Please review the below transactions and edit or reject them.
                        </span>
                    </div>

                    <v-data-table :headers="headers"
                                  :items="mappedTransactions"
                                  :item-class="getDefaultRowStyle"
                                  :items-per-page="5"
                                  class="elevation-5 mb-5 secondary"
                                  dark>

                        <template v-slot:[`item.approve`]="props">
                            <v-icon color="green" @click="editTransaction(props.item)">
                                mdi-check-bold
                            </v-icon>
                        </template>

                        <template v-slot:[`item.reject`]="props">
                            <v-icon color="red" @click="rejectTransaction(props.item)">
                                mdi-close-box
                            </v-icon>
                        </template>

                    </v-data-table>

                    <b-button v-b-toggle.sidebar-uncoveredTransactions class="btn-sm mr-2" style="position:absolute; top:10px; right:0px; background-color:var(--var-theme-color);">
                        <font-awesome-icon :icon="['fas', 'question-circle']" color="white" /><span class="white--text pl-2">Help</span>
                    </b-button>

                    <v-btn color="#FCF3CF" class="mt-4 float-left" small
                           @click="closeDialog()">
                        Cancel Changes
                    </v-btn>

                    <v-btn color="primary" class="mt-4 float-right" small
                           @click="saveDialog()">
                        Save Changes
                    </v-btn>

                    <br />
                    <br />
                </v-container>
            </v-card>
        </v-dialog>

        <!-- Edit Transaction -->
        <v-dialog v-model="showRowDetails"
                  persistent
                  max-width="600px">
            <v-card v-if="selectedItem !== null" dark>
                <v-card-title>
                    <span class="text-h5">Approve Transaction</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                    <tr>
                                        <td>Asset</td>
                                        <td>{{ selectedItem.asset }}</td>
                                    </tr>
                                    <tr>
                                        <td>Transaction Type</td>
                                        <td>{{ selectedItem.transactionType}}</td>
                                    </tr>
                                    <tr>
                                        <td>Quantity</td>
                                        <td>{{ selectedItem.quantity}}</td>
                                    </tr>
                                    <tr>
                                        <td>Price</td>
                                        <td>{{ selectedItem.price}}</td>
                                    </tr>
                                    <tr>
                                        <td>Date</td>
                                        <td>{{ selectedItem.timestamp}}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Spot Price
                                        </td>
                                        <td style="height:60px;">
                                            <v-text-field light
                                                          v-model="spotPrice"
                                                          placeholder="Spot Price"
                                                          :rules="[rules.required, rules.number]"
                                                          style="background-color:white; border-radius: 5px; height:30px; width:80%;"
                                                          dense>
                                            </v-text-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mt-6 mr-6"
                           text
                           color="red"
                           @click="showRowDetails = !showRowDetails">
                        Cancel
                    </v-btn>
                    <v-btn class="mt-6"
                           text
                           color="green"
                           @click="approveTransaction()">
                        Approve
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- SIDE BARS -->
        <div>
            <b-sidebar id="sidebar-uncoveredTransactions" title="Uncovered Transactions" right shadow bg-variant="dark" text-variant="light">
                <div class="px-3 py-2">
                    <p>
                        <font-awesome-icon :icon="['fas', 'pen-square']" class="fa-3x" color="var(--var-theme-color)" />
                        These transactions represent cryptocurrency sells, transfers or conversions that were not covered in the file you uploaded nor accounted for using the Stick-Shift <span style="font-size:large; font-weight:bold;">&#8482;</span> feature.
                        Using this screen, you can manually reject the transaction (which excludes it from your tax export) or edit the spot price so it can be included on your tax file as specified.
                    </p>
                    <p>
                        <em>
                            (Only use this feature if you are comfortable with calculating your capital gains for a given transaction in relation to the accounting
                            method you specified. We highly recommend that you re-upload this tax form and use the Stick-Shift feature as it will process your tax file with the chosen accounting method.)
                        </em>
                    </p>
                    <br />
                    <b-button v-b-toggle.sidebar-uncoveredTransactions class="mt-5" style="background-color:var(--var-theme-color);"><span class="white--text">Close</span></b-button>
                </div>
            </b-sidebar>

        </div>

    </v-row>
</template>

<script>

    import { notificationHooks } from '../shared/vue-mixin';

    export default {
        name: 'UncoveredTransactionsForm',

        /* COMPONENT DATA  */
        data() {
            return {
                currentUploadId: null,
                currentCorrections: [],
                currentTransactions: [],
                dialog: false,
                error: '',
                selectedItem: null,
                spotPrice: null,
                showRowDetails: false,
                secondaryBackground: {
                    backgroundImage: `url(${require('@/assets/dark-space-4.jpg')})`,
                    backgroundRepeat: 'repeat',
                },
                headers: [
                    { text: 'Asset', value: 'asset' },
                    { text: 'Transaction Type', value: 'transactionType' },
                    { text: 'Quantity', value: 'quantity' },
                    { text: 'Sell Price', value: 'price' },
                    { text: 'Date', value: 'timestamp' },
                    { text: 'Spot Price', value: 'spot', align: 'center' },
                    { text: 'Edit/Approve', value: 'approve', align: 'center' },
                    { text: 'Reject', value: 'reject', align: 'center' },
                    { text: 'Status', value: 'status', align: 'center', width: '100px' },
                ],
                rules: {
                    required: value => !!value || 'Required.',
                    number: value => {
                        const pattern = /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/
                        return pattern.test(value) || 'Invalid number'
                    },
                }
            };
        },

        /* MIXINS */
        mixins: [notificationHooks],

        /* COMPONENT METHODS  */
        methods: {

            /* PUBLIC METHODS  */
            ShowModal(transactionData) {
                this.error = "";
                this.dialog = true;
                //Clear out existing manual corrections
                this.currentUploadId = transactionData.uploadId;
                this.currentCorrections = transactionData.manualCorrections;
                this.currentTransactions = transactionData.uncoveredTransactions;
            },


            /* PRIVATE METHODS */
            editTransaction(item) {
                this.showRowDetails = true;
                this.selectedItem = item;

                if (this.selectedItem.spot !== null && this.selectedItem.spot !== "???") {
                    this.spotPrice = this.selectedItem.spot;
                }
                else
                    this.spotPrice = "";
            },

            approveTransaction() {

                if (isNaN(this.spotPrice) || this.spotPrice === "") {
                    this.showErrorMessage("CryptoTaxSpace", "You must supply a valid spot price.");
                    return;
                }

                if (this.spotPrice <= 0) {
                    this.showErrorMessage("CryptoTaxSpace", "You must supply a spot price greater than 0.");
                    return;
                }

                var item = this.selectedItem;

                var exists = false;
                //Replace existing correction if applicable
                this.currentCorrections.forEach(
                    current => {
                        if (current.id === item.id) {
                            current.status = "Approved";
                            current.spot = this.spotPrice;
                            exists = true;
                        }
                    });

                if (exists === false) {
                    //Add new correction
                    var correction = {
                        id: item.id,
                        status: 'Approved',
                        spot: this.spotPrice
                    };
                    this.currentCorrections.push(correction);
                }

                this.showRowDetails = false;
            },

            rejectTransaction(item) {

                var exists = false;
                //Replace existing correction if applicable
                this.currentCorrections.forEach(
                    current => {
                        if (current.id === item.id) {
                            current.status = "Rejected";
                            current.spot = "???";
                            exists = true;
                        }
                    });

                if (exists === false) {
                    //Add new correction
                    var correction = {
                        id: item.id,
                        status: 'Rejected',
                        spot: '???'
                    };
                    this.currentCorrections.push(correction);
                }
            },

            closeDialog() {
                this.error = "";
                this.$emit('modalClosed');
                this.dialog = false;
            },

            saveDialog() {
                this.error = "";
                var saveData = {
                    uploadId: this.currentUploadId,
                    corrections: this.currentCorrections
                }
                this.$emit('correctionsUpdated', saveData);
                this.dialog = false;
            },

            getDefaultRowStyle(record) {
                return "recordNeutralStyle";
            },

        },

        computed: {

            mappedTransactions() {

                var uncoveredTransactions = this.currentTransactions;
                var manualCorrections = this.currentCorrections;

                if (uncoveredTransactions !== undefined && uncoveredTransactions !== null) {
                    const fixedTransactions = uncoveredTransactions.map(function (transaction, index) {
                        manualCorrections.map(function (correction, index) {
                            if (correction.id === transaction.id) {
                                transaction.status = correction.status; //Change the transaction status to reflect the correction
                                transaction.spot = correction.spot;
                            }
                        });
                        return transaction;
                    });
                    return fixedTransactions;
                }

                return [];
            }

        }
    };
</script>

<style scoped>

    /deep/ .recordNeutralStyle {
        background-color: #BABFB8 !important;
        color: black !important;
    }
</style>